.m-table {
  width: 100%;

  &__heading {
    @extend .h3;
    margin: 0 0 floor($table-cell-padding * 1.5);
    padding-bottom: floor($table-cell-padding * 1.5);
    border-bottom: 1px solid $table-border-color;
    font-weight: normal;

    &--special {
      font-size: $font-size-h5;
      border-bottom-color: rgba(#000, 0.1);
    }
  }

  &__table {
    width: 100%;

    &--slim {
      width: auto;

      .m-table__tr {
        border: 0;
      }

      .m-table__th,
      .m-table__td {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  &__tr {
    border-top: 1px solid $table-border-color;

    &:first-child {
      border-top: 0;

      > .m-table__th,
      > .m-table__td {
        padding-top: 0;
      }
    }
  }

  &__th {
    font-weight: $font-weight-base;
  }

  &__th,
  &__td {
    padding: $table-cell-padding;
    vertical-align: top;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &--spacing {
    margin-bottom: $line-height-computed;
  }
}
