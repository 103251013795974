.m-promo-code {
  &,
  &[readonly],
  &[disabled] {
    @extend .form-control;
    border: none;
    background: $body-bg;
    box-shadow: none;
    cursor: text;
    color: $text-strong-color !important;
    text-align: center;
  }
}
