$brand-complementary: transparent !default;

.m-testimonials {
  position: relative;
  overflow: hidden;
  text-align: center;

  &__container {
    @extend .container;

    @media screen and (max-width: $grid-float-breakpoint) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &__indicators {
    margin-bottom: 30px;
  }

  &__select {
    margin-bottom: 40px;
  }

  &__description {
    margin-bottom: 40px;
    font-size: $font-size-h3;
    color: $text-strong-color;

    &--highlight {
      font-size: $font-size-h2;
    }
  }

  &__rating {
    font-size: 24px;
    color: $brand-primary;

    [class^="icon-star"] {
      &:before {
        margin-right: 0;
      }
    }
  }

  &__author {
    text-align: right;
    font-style: normal; // override default `cite` style
    color: rgba($text-strong-color, 0.5);

    &--left {
      text-align: left;
    }
  }

  &__action {
    margin: 0 24px;
    text-transform: lowercase;

    @media screen and (min-width: $grid-float-breakpoint) {
      float: left;
      width: calc(50% - 40px);
      margin: -100px 0 0 24px;
    }

    @media screen and (min-width: $screen-lg-min) {
      float: left;
      width: calc(50% - 140px);
      margin: -100px 0 0 100px;
    }
  }

  &__list {
    @include list-unstyled;
    @include clearfix;
    color: $text-strong-color;

    @media screen and (min-width: $grid-float-breakpoint) {
      display: flex;
      padding-bottom: 150px;
      margin-bottom: 0;
    }

    &--columns {
      .m-testimonials {
        &__list-item {
          margin-bottom: 0;

          &:nth-child(2n) {
            @media screen and (min-width: $grid-float-breakpoint) {
              margin-bottom: 0;
              padding-top: 150px;
              align-self: flex-start;
            }
          }
        }

        &__item {
          margin-bottom: $grid-gutter-width;

          &--type-2 {
            margin-bottom: ($grid-gutter-width + 24);
          }
        }
      }

      padding-bottom: 50px;
    }
  }

  &__list-item {
    margin-bottom: 30px;
    overflow: hidden;

    @media screen and (min-width: $grid-float-breakpoint) {
      flex: 1 1 50%;

      &:nth-child(2n) {
        position: relative;
        margin-bottom: -150px;
        margin-left: -20px;
        align-self: flex-end;
      }
    }
  }

  &__item {
    &--type-1 {
      @include sensei-border($pattern-url, $screen-lg-min, null, null, -24px -40px 0 -100px, null);
      margin: 24px 24px 0;

      @media screen and (min-width: $screen-lg-min) {
        margin: 24px 40px 0 100px;
      }
    }

    &--type-2 {
      @include sensei-border($pattern-url, $screen-lg-min, null, null, null, 40% -100px -24px 0);
      margin: 0 24px 24px;

      @media screen and (min-width: $screen-lg-min) {
        margin: 0 100px 24px 0;
      }

      .m-testimonials__item-content {
        background-color: $brand-secondary;
      }
    }

    &--type-1-line,
    &--type-2-line {
      .m-testimonials__item-content {
        background-color: $brand-complementary;
      }
    }

    &--type-1-line {
      @media screen and (min-width: $screen-lg-min) {
        margin: 0 40px 0 100px;
      }
    }

    &--type-2-line {
      @media screen and (min-width: $screen-lg-min) {
        margin: 0 100px 24px 0;
      }
    }
  }

  &__item-content {
    position: relative;
    z-index: abs($zindex-navbar - 10);
    padding: 40px;
    background: $body-bg;
    text-align: left;
  }

  &__content {
    margin: 0;
    padding: 0;
    border: none;
    font-size: inherit; // override `blockquote` defaults from Bootstrap
  }

  &__section-title {
    @media screen and (min-width: $grid-float-breakpoint) {
      margin-bottom: 40px;
    }
  }

  &__response {
    margin: $line-height-computed 0 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}
