.m-special-box {
  &__featured {
    margin-bottom: 20px;
  }

  &__inner {
    @include sensei-border($pattern-url, $grid-float-breakpoint, -20px 20% 20px -30px, 80px -30px -20px 70%);
    margin-right: auto;
    margin-left: auto;

    @media screen and (min-width: $grid-float-breakpoint) {
      width: 80%;
    }
  }

  &__heading {
    color: inherit;
  }

  &__content {
    position: relative;
    z-index: abs($zindex-navbar - 10);
    padding: 2.5em 2.5em 3.5em;
    background-color: $brand-secondary;
    color: rgba(#000, 0.5);
  }

  .m-table__heading,
  .m-table__td {
    color: inherit;
  }
}
