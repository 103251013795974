.m-indicators {
  &--spacing {
    margin-bottom: 30px;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li, // same as .m-indicators__list-item (required by Splide pagination)
  &__list-item {
    display: inline-block;
  }

  &__btn {
    @extend %button-unstyled;
    padding: 10px 5px;

    &:focus,
    &:hover,
    &.is-active {
      &:before {
        background-color: #000;
      }
    }

    &:before {
      content: "";
      display: block;
      width: 40px;
      height: 2px;
      background-color: rgba(#000, 0.1);
      transition: background-color $base-duration;
    }
  }

  &:empty {
    display: inline-block !important; // override Splide styles (we always want to show the indicator here, even if it's only one).
    padding: 10px 5px;

    &:before {
      display: block;
      width: 40px;
      height: 2px;
      background-color: #000;
      content: " ";
    }
  }

  &--brand {
    &:empty:before {
      background-color: $brand-primary;
    }
  }

  &--muted {
    &:empty:before {
      width: 32px;
      background-color: rgba(#000, 0.1);
    }
  }
}
