.m-info-box {
  &__list {
    @include list-unstyled;

    @media screen and (min-width: $grid-float-breakpoint) {
      display: flex;
      flex-flow: row wrap;
    }
  }

  &__list-item {
    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;
    text-align: center;

    @media screen and (min-width: $grid-float-breakpoint) {
      padding-right: $grid-gutter-width;
      padding-left: $grid-gutter-width;
      flex: 1 1 (100% / 3);
      max-width: (100% / 3); /* fix IE 11 flex-basis bug */
    }
  }

  &__item {
    @extend .u-link-wrapper;
    display: block;

    &:hover {
      animation: pulse $base-duration;
    }
  }

  &__icon {
    display: flex;
    margin: 0 auto 30px;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;

    &--predefined {
      width: 82px;
      height: 82px;
      border: 1px solid $separator-color;
      border-radius: 50%;
      background-color: $brand-primary;
    }
  }

  &__title {
    @extend .h4;
  }

  &__description {
    margin-bottom: 3.5em;
  }
}
