%m-page-header-indicator {
  &:after {
    // Styles are based on `.m-indicators:empty`
    display: block;
    width: 40px;
    height: 2px;
    margin: (floor($line-height-computed / 2.2) + 10) auto 0;
    background-color: #000;
    content: " ";
  }
}

.m-page-header {
  @include clearfix;
  text-align: center;

  &__title {
    @extend .h2;
    margin: 0 0 floor($line-height-computed / 2.2);
    font-weight: $font-weight-base;

    &--primary {
      font-size: $font-size-h1;
    }

    &--indicator {
      @extend %m-page-header-indicator !optional;
    }
  }

  &__description {
    margin: 0 0 floor($line-height-computed / 1.8);
  }

  &--spacing {
    margin-bottom: floor($line-height-computed * 2);
  }
}

// Add compatibility with shared modules (apply Sensei header style)
.content-header {
  @extend .m-page-header;

  h1 {
    @extend .m-page-header__title;
    @extend .m-page-header__title--primary;
    @extend .m-page-header--spacing;
    @extend %m-page-header-indicator;
  }

  h2 {
    @extend .m-page-header__title;
  }
}
