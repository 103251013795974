// TODO: Refactor cart and cart info to BEM
.m-cart-info {
  &__list {
    @include clearfix;
    margin: 0;
    padding: 0;
    list-style: none;

    > li {
      float: left;
    }
  }

  .item-counter {
    color: #fff;
  }

  .cart-empty-message {
    display: none;
  }

  // TODO: hide checkout button for all themes
  .cart-action {
    display: none;
  }
}
