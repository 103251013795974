// Base
$border-radius-base: 0;
$hover-opacity: 0.7;

// Colors
$separator-color: #f2f2f2;
$text-strong-color: #444;
$brand-primary: #dd2b50;
$brand-secondary: #e9c2ca;
$brand-complementary: #fafafa;
$brand-success: #007022;
$text-color: #6b6b6b;
$text-muted: lighten($text-color, 10%);
$body-bg: #fff;
$link-color: $brand-primary;
$link-hover-decoration: none;
$headings-color: $text-strong-color;
$table-border-color: $separator-color;
$popover-border-color: $separator-color;
$m-popover-medium-max-width: 400px;
$input-border-focus: $brand-primary;
$blockquote-border-color: rgba(0, 0, 0, 0.07);
$state-success-text: $brand-success;

// Typography
$font-size-base: 14px;
$font-size-small: 13px;
$em-base: $font-size-base;
$font-size-h1: floor(($font-size-base * 2.6)); // ~36px
$font-size-h2: floor(($font-size-base * 2.15)); // ~30px
$font-size-h3: ceil(($font-size-base * 1.55)); // ~22px
$headings-font-weight: normal;
$headings-line-height: 1.4;
$form-control-font-size-base: 16px;

// Layout
$pattern-url: "../images/sensei/f8410fb37f4399e8343c8775fa8e9454.png";
$grid-gutter-width: 15px;
$grid-gutter-height: $grid-gutter-width;
$grid-text-xs-gutter-width: 15px;
$container-large-desktop: (1180px + $grid-gutter-width);
$padding-base-vertical: 6px;
$padding-base-horizontal: 15px;
$padding-large-vertical: 14px;
$padding-large-horizontal: 35px;
$line-height-base: 1.8;

// Sections
$m-section-margin: 0 0 55px;
$m-section-margin-small: 0 auto 55px;
$m-section-margin-lg-viewport: 0 auto 100px;
$m-section-separator-vertical-padding: $grid-gutter-height * 2;

// Buttons
$btn-default-color: $brand-primary;
$btn-default-border: $btn-default-color;
$btn-default-bg: transparent;
$btn-text-transform: uppercase;
$btn-border-radius-large: 0;
$btn-font-size-large: inherit;

// Menu
$m-group-nav-link-hover-color: $brand-primary;
$m-group-header-link-hover-color: $m-group-nav-link-hover-color;
$m-flex-layout-content-width: 75%;
$m-flex-layout-aside-width: 25%;
$m-flex-layout-aside-max-width: none;
$m-list-gutter: 20px;
$m-item-gutter-base: floor($grid-gutter-width);
$m-item-price-font-color: $brand-primary;

// Restaurant hours
$m-restaurant-hours-row-padding: 0px;

// Restaurant Selection
$m-restaurants-content-margin-block-end: 56px;
$m-restaurants-card-padding: 24px 32px;

// Shared dependencies
@import 'base/shared-themes-dependencies';

// Custom styles
@import 'base/shared-essentials';
@import 'base/shared-themes';
@import 'base/sensei/overrides';
@import 'modules/m-section';
@import 'modules/m-buttons';
@import 'modules/m-floating-action';
@import 'modules/m-page-subheader';

// Sensei
@import 'modules/sensei/m-header';
@import 'modules/sensei/m-table';
@import 'modules/sensei/m-page-header';
@import 'modules/sensei/m-icon-group';
@import 'modules/sensei/m-slider';
@import 'modules/sensei/m-indicators';
@import 'modules/sensei/m-nav';
@import 'modules/sensei/m-sticky-navbar';
@import 'modules/sensei/m-media';
@import 'modules/sensei/m-promo-code';
@import 'modules/sensei/m-testimonials';
@import 'modules/sensei/m-info-box';
@import 'modules/sensei/m-place-info';
@import 'modules/sensei/m-action';
@import 'modules/sensei/m-teasers';
@import 'modules/sensei/m-cover';
@import 'modules/sensei/m-content';
@import 'modules/sensei/m-cart-info';
@import 'modules/sensei/m-special-box';
@import 'modules/sensei/m-checkout';
@import 'modules/sensei/m-footer';

img {
  @include img-responsive();
}

.stars {
  img {
    display: inline;
  }
}

button,
a {
  transition: color, background-color, opacity;
  transition-duration: $base-duration;
}

.dropdown-menu {
  animation: fadeIn $base-duration;
}

.cart .item .column.info .name,
.highlight,
.text-strong {
  font-weight: $font-weight-bold;
  color: $headings-color;
}

.text-semi-strong {
  color: $headings-color;
}

.m-pagination__item,
.m-table__td {
  color: $headings-color;
}

.text-teaser {
  font-size: 140%;
  color: $headings-color;
}

.text-columns {
  columns: 2;
  column-gap: floor($line-height-computed * 1.5);
}

.icon-ok {
  color: $brand-success;
}

.svg-icon-twitter {
  color: #28aae1;
}

.svg-icon-facebook {
  color: #3c5a98;
}

.svg-icon-instagram {
  color: #d42b6a;
}

// Space between content and header with multiple restaurants
.content-content {
  + .content-header {
    margin-top: $base-spacing-large;
  }
}

// Menu overrides - START

.m-group-nav {
  font-size: 1.2em;
  color: $headings-color;

  &__list-item {
    border-bottom: 0 solid $separator-color;
  }

  &__link-icon {
    display: inline-block;
    margin-left: 10px;
  }

  &--horizontal,
  &--aside {
    &:after {
      @include position(absolute, auto 0 -16px 0);
      height: 12px;
      background: url($pattern-url) 0 0 repeat;
      content: " ";
    }
  }

  &--aside {
    padding: 0 25px;
    border: 1px solid $separator-color;

    .m-group-nav__list-item {
      border-bottom-width: 1px;

      &:last-child {
        border-bottom: 0;
      }
    }

    .m-group-nav__link {
      padding: 15px 0;
    }
  }

  &--tabs {
    .m-group-nav__link {
      font-size: inherit;
    }
  }
}

.m-item {
  &--list {
    .m-item__image-aside--thumbnail {
      @media screen and (min-width: $screen-lg-min) {
        width: 200px;
      }
    }
  }

  &__badge {
    background-color: $brand-primary;
  }
}

.item-counter,
.m-item__badge {
  @extend .badge;
}

.item-counter {
  background-color: $brand-primary;
}

// Menu overrides - STOP
