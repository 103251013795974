.m-cover {
  background-color: $brand-secondary;
  color: #fff;

  &__list {
    @include list-unstyled;
    margin: 0;
  }

  &__list-item {
    position: relative;
  }

  &__picture {
    @include position(absolute, 0 0 0 0);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  &__picture-img {
    &--fit {
      @include object-fit;
    }
  }

  &__container {
    @extend .container;
  }

  &__content {
    padding: $grid-gutter-width 0;
    text-align: center;
  }

  &__content-inner {
    position: relative; // required as a popover container

    + .m-cover__content-inner {
      margin-top: $grid-gutter-width;
    }
  }

  &__title {
    @extend .h1;
    color: inherit;
  }

  &__description {
    @include font-smoothing();
  }

  &__indicators {
    margin-bottom: 30px;

    .m-indicators {
      &:empty {
        &:before {
          background-color: currentColor;
        }
      }

      &__btn {
        &:hover,
        &:focus,
        &.is-active {
          &:before {
            background-color: currentColor;
          }
        }
      }
    }
  }

  &__sublist {
    @include list-unstyled;
    text-align: left;
    display: flex;
    flex-flow: row nowrap;
    margin-left: ceil($grid-gutter-width / -2);
    margin-right: ceil($grid-gutter-width / -2);

    @media screen and (max-width: $screen-xs-max) {
      &:not(.splide__list) {
        flex-wrap: wrap;
      }
    }
  }

  &__sublist-list-item {
    padding: ceil($grid-gutter-width / 2);
    display: flex;

    &:not(.splide__slide) {
      flex: 1 1 (100% / 4);

      @media screen and (max-width: $screen-xs-max) {
        &--xs-2 {
          &:not(.splide__slide) {
            min-width: 50%;
            flex-basis: 50%;
          }
        }
      }
    }
  }

  &__sublist-item {
    flex-grow: 1;
    padding: floor($grid-gutter-width);
    background-color: rgba(#000, 0.15);
    border-radius: 4px;
    color: inherit;

    &:hover,
    &:focus {
      color: inherit;
    }

    &:focus {
      @include tab-focus;
    }
  }

  &__sublist-item-title {
    @extend .h5;
    margin: 0 0 0.2em;
    color: inherit;
  }

  &__sublist-item-desc {
    @include font-smoothing();
    font-size: 0.8571em;
    line-height: $line-height-small;
  }

  .popover {
    color: $text-color;

    p:last-child {
      margin-bottom: 0;
    }
  }
}
