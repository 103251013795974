.m-slider {
  position: relative;
  overflow: hidden;

  &__list {
    @include list-unstyled;
    @include clearfix;
    margin: 0;
  }

  &__list-item {
    position: relative;
    float: left;
    min-height: 1px;
    width: 100vw; // prevent "Flash of Unstyled Content" effect

    &--absolute {
      @media screen and (min-width: $grid-float-breakpoint) {
        padding-bottom: 258px;
      }
    }
  }

  &__slide-picture {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px; // required for object-fit fallback
    overflow: hidden;
    background-color: $brand-secondary;

    @media screen and (max-width: $grid-float-breakpoint-max) {
      max-height: 30vh;
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      height: 360px;
    }
  }

  &__slide-img {
    &--fit {
      @include object-fit;
    }
  }

  &__slide-container {
    @extend .container;
    position: relative;

    @media screen and (max-width: $grid-float-breakpoint-max) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &__slide-content {
    @include sensei-border();
    margin: 0 auto 30px;
    text-align: center;

    @media screen and (min-width: $grid-float-breakpoint) {
      margin-top: -80px;
      width: 90%;
    }

    @media screen and (min-width: $screen-lg-min) {
      width: 66%;
    }

    &--absolute {
      @media screen and (min-width: $grid-float-breakpoint) {
        @include position(absolute, null null -258px 50%);
        margin-left: -45%;

        &:after {
          top: calc(100% - 227px);
        }
      }

      @media screen and (min-width: $screen-lg-min) {
        margin-left: -33%;
      }

    }
  }

  &__slide-content-inner {
    position: relative;
    z-index: abs($zindex-navbar - 10);
    padding: 1.5em;
    min-height: 80px;
    background-color: $body-bg;

    @media screen and (min-width: $grid-float-breakpoint) {
      padding: 2.5em 2.5em 3.5em;
    }
  }

  &__indicators {
    @media screen and (max-width: $screen-xs-min) {
      position: absolute;
      top: -50px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__title {
    @extend .h1;
    font-weight: $font-weight-base;

    @media screen and (max-width: $screen-xs-min) {
      margin-top: 0;
    }
  }

  &__description {
    margin-bottom: 2.5em;
  }

  &__action {
    text-transform: uppercase;
  }
}
