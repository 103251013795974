// Bootstrap overrides - START

.container,
.container-fluid {
  @media screen and (max-width: $grid-float-breakpoint-max) {
    padding-right: $grid-text-xs-gutter-width;
    padding-left: $grid-text-xs-gutter-width;
  }
}

.form-control,
input,
select,
textarea {
  &:focus {
    box-shadow: none;
  }
}

.help-block {
  color: $text-muted; // Default color from Bootstrap is too difficult to read (lighten($text-color, 25%)).

  @at-root .success {
    .help-block {
      color: $brand-success;
      text-align: right;
    }
  }

  @at-root .error {
    .help-block {
      color: $brand-danger;
      text-align: right;
    }
  }
}

.popover-header {
  font-weight: $font-weight-bold;
}

// Bootstrap overrides - STOP
