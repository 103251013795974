.m-place-info {
  line-height: $line-height-computed;

  &__title {
    margin: 0;
    color: $text-strong-color;
  }

  &__name {
    font-weight: $font-weight-bold;
    font-size: 25px;
    color: inherit;
  }

  &__name-addition {
    text-transform: lowercase;
  }

  &__description {
    margin: 0;
  }
}
