$m-header-gutter: $grid-gutter-width !default;
$m-header-height: 138px !default;
$m-header-line-height: 30px !default; // Because of specific layout design it needs to be in px units.

.m-header {
  &__container {
    @extend .container;
  }

  &__base {
    display: flex;
    position: relative;
    border-bottom: 1px solid $separator-color;
  }

  &__main {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $m-header-gutter;
    text-align: center;

    @media screen and (min-width: $grid-float-breakpoint) {
      //height: $m-header-height; // Height is required if `.m-header__aside` element is positioned absolutely.
    }

    .m-brand__logo {
      display: inline-block;
    }

    .m-brand__logo-img {
      max-width: 160px;
      max-height: floor($m-header-height - ($m-header-gutter * 2));
      width: auto;
      height: auto;

      @media screen and (max-width: $grid-float-breakpoint-max) {
        max-width: 80px;
      }
    }
  }

  &__aside {
    display: flex;
    align-items: center;
    padding-top: $m-header-gutter;
    padding-bottom: $m-header-gutter;
    max-width: 30%; // we need to make sure that logo (main element) has enough space

    &:first-child {
      @include position(absolute, 0 auto 0 0);
      padding-right: $m-header-gutter;
      border-right: 1px solid $separator-color;

      @media screen and (min-width: $grid-float-breakpoint) {
        padding-left: $m-header-gutter;
      }
    }

    &:last-child {
      @include position(absolute, 0 0 0 auto);
      justify-content: flex-end;
      padding-left: $m-header-gutter;
      border-left: 1px solid $separator-color;

      .m-language-select {
        &__dropdown-menu {
          right: auto;
          left: 0;
        }
      }

      @media screen and (min-width: $grid-float-breakpoint) {
        padding-right: $m-header-gutter;
      }
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      display: flex;
      align-items: flex-start;
      padding-top: floor(($m-header-height - $m-header-line-height) / 2);
    }

    .m-place-info {
      @media screen and (max-width: $screen-md-max) {
        display: none;
      }
    }
  }

  &__aside-inner {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $screen-lg-min) {
      justify-content: space-between;
      height: 100%;
    }

    &--row {
      flex-direction: row;

      @media screen and (min-width: $screen-lg-min) {
        flex-direction: column;
      }

      .m-language-select {
        margin-right: 8px;

        @media screen and (min-width: $screen-sm-min) {
          margin-right: 0;
          margin-left: 8px;
        }

        @media screen and (min-width: $screen-lg-min) {
          align-self: flex-end;
          margin-left: 0;
        }
      }
    }
  }

  &--special {
    margin-bottom: 80px;
    border-bottom: 1px solid $separator-color;
  }

  &--special-simple {
    border-bottom: 1px solid $separator-color;

    .m-header__base {
      border-bottom: none;
    }
  }
}

.m-brand {
  margin: 0;
}
