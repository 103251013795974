$brand-complementary: transparent !default;
$text-strong-color: inherit !default;

// Checkout module doesn't follow BEM guidelines because of the limited control over markup and shared code for all themes.

.m-checkout {
  margin-bottom: 20px;
  color: $text-strong-color;

  &__cols {
    display: flex;
    flex-flow: column nowrap;

    @media screen and (min-width: $screen-md-min) {
      flex-direction: row;
    }
  }

  .column-form {
    width: auto;
    order: 2;

    @media screen and (min-width: $screen-md-min) {
      margin-right: 25px;
      padding: 25px;
      border-right: 1px solid $separator-color;
      flex: 1 1 auto;
    }

    @media screen and (min-width: $screen-lg-min) {
      margin-right: 55px;
      padding: 55px;
    }
  }

  .column-summary {
    order: 1;
    width: auto;

    @media screen and (min-width: $screen-md-min) {
      padding-top: 25px;
      flex: 1 0 380px;
      order: 3;
    }

    @media screen and (min-width: $screen-lg-min) {
      padding-top: 55px;
    }
  }

  .section-subheading {
    @extend .h3;
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid $separator-color;

    &:first-of-type {
      @media screen and (min-width: $screen-md-min) {
        margin-top: 0;
      }
    }

    &--aside {
      padding-bottom: 0;
      border: none;
    }
  }

  .card {
    padding: 15px 30px;
    box-shadow: none;
    background-color: $brand-complementary;
  }

  .summary-view {
    margin-bottom: 0;
    font-size: $font-size-small;

    tr {
      &.highlight {
        td {
          border-top: 1px solid $text-strong-color;
        }
      }
    }

    th {
      padding-top: 18px;
      padding-bottom: 18px;
      border-bottom: 0;
      font-weight: $font-weight-base;
      text-transform: uppercase;
    }

    td {
      padding-top: 18px;
      padding-bottom: 18px;
      color: $text-strong-color;
    }

    th,
    td {
      text-align: center;

      &:first-child {
        padding-left: 0;
        text-align: left;
      }

      &:last-child {
        padding-right: 0;
        text-align: right;
      }
    }
  }

  @media screen and (min-width: $grid-float-breakpoint) {
    #order_user_parsed_phone_input,
    #order_user_email_input {
      float: left;
      width: 48.5%;
    }

    #order_user_parsed_phone_input {
      margin-right: 3%;
    }

    #order_user_subscribed_input {
      clear: both;
      font-size: $font-size-small;
      color: $text-muted;
    }
  }

  // Checkout layout
  section {
    margin: $m-section-margin;

    &:last-child {
      margin-bottom: 0;
    }

    &.delivery {
      margin-bottom: 0;
    }

    &.confirmation,
    &.payment {
      margin-bottom: 30px;
      padding-bottom: 10px;
      border-bottom: 1px solid $separator-color;
    }
  }

  #order_delivery_address_apt_number_input,
  #order_delivery_address_floor_input {
    float: left;
    width: 48.5%;
  }

  #order_delivery_address_apt_number_input {
    margin-right: 3%;
  }

  .form-actions {
    [type="submit"] {
      @extend .btn-lg;
      font-size: inherit;
      margin-top: 20px;
      text-transform: uppercase;
    }
  }
}
