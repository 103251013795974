.m-action {
  margin: 0;
  font-size: 170%;

  &__icon {
    margin-left: 0.25em;
    vertical-align: middle;
  }

  &--normal {
    font-size: inherit;
  }

  &__link {
    @extend %button-unstyled;
    color: $brand-primary;

    &:focus,
    &:hover {
      border-color: darken($brand-primary, 15%);
      color: darken($brand-primary, 15%);
      text-decoration: $link-hover-decoration;
    }

    &--special {
      display: block;
      border-top: 2px solid $brand-primary;
      padding-top: 30px;
      padding-right: 20px;
      text-align: right;
      line-height: $line-height-small;

      &:focus,
      &:hover {
        text-decoration: none;
      }
    }
  }

  &--regular {
    font-size: 100%;
  }
}
