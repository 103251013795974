$brand-complementary: transparent !default;

.m-teasers {
  text-align: center;

  &__list {
    @include list-unstyled;
    margin-right: floor(($grid-gutter-width / -2));
    margin-left: ceil(($grid-gutter-width / -2));

    @media screen and (min-width: $screen-xs-min) {
      display: flex;
      flex-flow: row nowrap;

      &:not(.splide__list) {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }

  &__list-item {
    padding-top: 12px;
    padding-right: floor(($grid-gutter-width / 2));
    padding-left: ceil(($grid-gutter-width / 2));
    text-align: center;

    @media screen and (min-width: $screen-xs-min) {
      display: flex;
      flex-basis: (100% / 2);
      max-width: (100% / 2);
    }

    @media screen and (min-width: $screen-md-min) {
      flex-basis: (100% / 3);
      max-width: (100% / 3);
    }

    @media screen and (min-width: $screen-lg-min) {
      flex: 1 1 (100% / 4);
      max-width: (100% / 4);
    }

    &--special {
      position: relative;
      margin-bottom: 25px;

      @media screen and (min-width: $screen-xs-min) {
        padding-top: 0;
        padding-right: 10px;
        padding-left: 10px;
        flex-basis: (100% / 2);
        max-width: (100% / 2);

        &:before {
          @include position(absolute, 140px -25px 20% 50%);
          height: auto;
          background: url($pattern-url) 0 0 repeat;
          content: " ";
        }

        &:last-child {
          &:before {
            display: none;
          }
        }

        @media (max-width: $screen-md-max) {
          &:nth-child(2n) {
            &:before {
              display: none;
            }
          }
        }
      }

      @media screen and (min-width: $screen-sm-min) {
        padding-right: 25px;
        padding-left: 25px;
      }

      @media screen and (min-width: $screen-lg-min) {
        flex-basis: (100% / 3);
        max-width: (100% / 3);

        &:nth-child(3n) {
          &:before {
            display: none;
          }
        }
      }

      .m-teasers__item {
        &:before {
          display: none;
        }
      }
    }

    &.splide__slide {
      flex: 1 0 auto;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: $brand-complementary;
    margin-bottom: $grid-gutter-width;
    width: 100%; // fixes IE 11 bug regarding width of the flex container

    &[href],
    &[href]:hover,
    &[href]:focus {
      color: inherit;
      text-decoration: none;
      font-weight: inherit;
    }

    &[href]:focus {
      outline: $outline-default;
      outline-offset: -2px;
    }

    &:before {
      @include position(absolute, -12px 0 0 0);
      height: 12px;
      background: url($pattern-url) 0 0 repeat;
      content: " ";
    }
  }

  &__item-content {
    padding: $grid-gutter-width;

    @media screen and (min-width: $screen-xs-min) {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }

  &__media {
    margin-bottom: 0;
    max-height: 180px;
    overflow: hidden;

    &--special {
      max-height: 140px;
    }
  }

  &__media-img {
    margin-bottom: ($line-height-computed / 2);
    width: 100%;
  }

  &__title {
    @extend .h4;
    margin-top: 0;
  }

  &__subtitle {
    margin-bottom: ($line-height-computed / 2);
    color: $text-strong-color;
  }

  &__description {
    margin-bottom: 2.25em;
    flex-grow: 1;
  }

  &__indicators {
    margin-bottom: 30px;
  }

  &__subaction {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    &--single {
      justify-content: center;
    }

    &--single-inner {
      margin-bottom: 2.25em;
      justify-content: center;
    }
  }

  &__value {
    color: $brand-primary;
  }

  &__availability {
    width: 100%;
    overflow-x: auto;
    min-height: 0.01%;

    > * {
      margin-bottom: 0;
    }
  }

  &__section-title {
    @media screen and (min-width: $grid-float-breakpoint) {
      margin-bottom: 40px;
    }
  }

  &__select {
    margin-bottom: 40px;
  }
}
