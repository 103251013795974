.m-icon-group {
  &__list {
    margin: 0 -5px;
    padding-left: 0;
    list-style: none;
  }

  &__list-item {
    display: inline-block;
    vertical-align: middle;
    padding-right: 5px;
    padding-left: 5px;
  }

  &__item-link {
    display: block;
    color: inherit;

    &:hover,
    &:focus {
      color: inherit;
      text-decoration: none;
      opacity: $hover-opacity;
    }
  }

  &__item-label {
    margin-left: ceil($grid-gutter-width / 2);
    color: $text-strong-color
  }

  &__icon-rounded {
    display: inline-block;
    width: 24px;
    height: 24px;
    color: #000;
    text-align: center;
    line-height: 24px;
    border: 1px solid #000;
    border-radius: 50%;
  }
}
