// Module for spacing between content elements.

$m-section-margin: null !default;
$m-section-padding: null !default;
$m-section-margin-small: null !default;
$m-section-margin-lg-viewport: null !default;
$m-section-padding-lg-viewport: null !default;
$m-section-separator-vertical-padding: $base-spacing !default;

.m-section {
  @if ($m-section-margin != null) {
    margin: $m-section-margin;
  }

  @if ($m-section-padding != null) {
    padding: $m-section-padding;
  }

  @if ($m-section-margin-small != null) {
    &--small {
      margin: $m-section-margin-small;
    }
  }

  @media screen and (min-width: $grid-float-breakpoint) {
    @if ($m-section-margin-lg-viewport != null) {
      margin: $m-section-margin-lg-viewport;
    }

    @if ($m-section-padding != null) {
      padding: $m-section-padding-lg-viewport;
    }

    @if ($m-section-margin-small != null) {
      &--small {
        margin: $m-section-margin-small;
      }
    }
  }

  &--separator {
    padding-top: $m-section-separator-vertical-padding;
    padding-bottom: $m-section-separator-vertical-padding;
    border-top: 1px solid $separator-color;
    border-bottom: 1px solid $separator-color;
  }

  &--separator-t {
    padding-top: $m-section-separator-vertical-padding;
    border-top: 1px solid $separator-color;
  }

  &--separator-b {
    padding-bottom: $m-section-separator-vertical-padding;
    border-bottom: 1px solid $separator-color;
  }
}
