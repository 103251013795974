.m-footer {
  &__container {
    @extend .container;
  }

  &__content {
    @media screen and (min-width: $screen-xs-min) {
      display: flex;
      margin-right: floor($grid-gutter-width / -2);
      margin-left: ceil($grid-gutter-width / -2);
      padding: $grid-gutter-width 0 0;
      align-items: stretch;
      flex-flow: row wrap;
    }
  }

  &__col {
    margin-bottom: 30px;

    .m-brand__logo {
      display: inline-block;
      margin-right: auto;
      margin-left: auto;
      max-width: 160px;
    }

    @media screen and (min-width: $screen-xs-min) {
      margin-right: floor(($grid-gutter-width / 2));
      margin-left: ceil(($grid-gutter-width / 2));
      flex: 1 1 auto;

      &--slim {
        flex: 0 0 auto;

        &:first-child {
          padding-right: 6%;
        }
      }
    }
  }

  &__info-content {
    padding: $grid-gutter-width 0;
    border-top: 1px solid $separator-color;
  }

  &:before {
    margin: 0 0 40px;
    display: block;
    height: 20px;
    background: url($pattern-url) 0 0 repeat;
    content: " ";
  }

  &__app-badges {
    display: flex;

    @media screen and (min-width: $grid-float-breakpoint) {
      max-width: 245px;
    }
  }
}
