$m-sticky-navbar-bg: $body-bg !default;

.m-sticky-navbar {
  &__base {
    @include position(fixed, 0 0 null 0);
    z-index: $zindex-navbar-fixed;
    border-bottom: 1px solid $separator-color;
    background: $m-sticky-navbar-bg;
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
    transition: transform $base-duration, visibility $base-duration;

    .m-brand__logo-img {
      max-height: 50px;
      width: auto;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
  }

  &__content-item {
    display: flex;
    padding: ceil($grid-gutter-width / 1.5) ceil($grid-gutter-width);
    border-right: 1px solid $separator-color;
    align-items: center;
    flex: 0 0 auto;
    align-self: stretch;

    &--info {
      flex: 1 1 auto;
    }

    &--action {
      flex-shrink: 1;
    }

    &:first-child {
      &:not(.hidden-xxs) {
        @media screen and (max-width: $grid-float-breakpoint-max) {
          flex-grow: 1;
        }
      }
    }

    &:last-child {
      border-right: 0;
    }

    .popover {
      margin-top: 28px;
    }

    .m-group-nav__link--dropdown-toggle {
      padding: 3px 5px 3px 0;
    }
  }

  &.is-affixed {
    position: static; // override `fixed` position from Bootstrap affix plugin

    .m-sticky-navbar__base {
      visibility: visible;
      transform: translate3d(0, 0, 0);
    }

    .m-icon-group__item-link {
      margin-bottom: -15px; // additional space for popover
      padding-bottom: 15px;
    }
  }

  .m-group-nav__list--dropdown {
    max-width: 80vw;
    white-space: nowrap;
  }
}
