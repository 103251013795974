.m-content {
  &__featured {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 320px;
    overflow: hidden;
    background-color: $brand-secondary;
  }

  &__featured-img {
    &--fit {
      @include object-fit;
    }
  }

  &__title {
    @extend .h1;
  }

  &__container {
    @extend .container;
    max-width: 920px;
  }

  &__header {
    margin-bottom: $line-height-computed;
    text-align: center;
  }

  &__content {
    position: relative;
    padding: 60px 0 $grid-gutter-width;
    background-color: $body-bg;

    @media screen and (min-width: $grid-float-breakpoint) {
      margin-top: -150px;
      padding-right: 75px;
      padding-left: 75px;
    }
  }

  &__content-base {
    text-align: justify;

    p {
      margin-bottom: $line-height-computed;
    }
  }
}
