$brand-complementary: transparent !default;

.m-media {
  position: relative;
  overflow: hidden;
  text-align: center;

  &__container {
    @extend .container;
    display: flex;

    @media screen and (max-width: $grid-float-breakpoint-max) {
      flex-grow: 1;
    }
  }

  &__list {
    @include list-unstyled;
    @include clearfix;
    margin: 0;
  }

  &__list-item {
    position: relative;
    float: left;
    background-color: $brand-complementary;
    min-height: 1px;
    width: 100vw; // prevent "Flash of Unstyled Content" effect
    overflow: hidden;
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (min-width: $grid-float-breakpoint) {
      display: table;
      min-height: 240px;
    }
  }

  &__picture,
  &__item-content {
    @media screen and (min-width: $grid-float-breakpoint) {
      display: table-cell;
      width: 50%;
    }
  }

  &__item-content {
    flex-grow: 1;
    padding: $grid-gutter-width 0;

    @media screen and (min-width: $grid-float-breakpoint) {
      padding: 5% 5% 4%;
    }
  }

  &__picture {
    margin-right: floor($grid-text-xs-gutter-width / -1);
    margin-left: ceil($grid-text-xs-gutter-width / -1);

    @media screen and (min-width: $grid-float-breakpoint) {
      @include position(absolute, 0 0 0 auto);
      display: table-cell;
      width: 50%;
      margin-right: 0;
      margin-left: 0;
      overflow: hidden;
    }
  }

  &__item-img {
    @media screen and (min-width: $grid-float-breakpoint) {
      @include object-fit;
    }
  }

  &__title {
    @extend .h3;
    color: $brand-primary;
    font-weight: $font-weight-base;
  }

  &__description {
    margin: 0 0 1.8em;
  }

  &__code {
    margin: 0 auto 1.5em;
    text-align: center;

    @media screen and (min-width: $grid-float-breakpoint) {
      width: 70%;
    }
  }

  &__item-action {
    margin-bottom: 0;
    text-transform: uppercase;
  }

  &__indicators {
    margin-bottom: 30px;
  }

  &__action {
    @media screen and (min-width: $grid-float-breakpoint) {
      width: 50%;
      margin-left: auto;
    }
  }
}
