$m-nav-gutter: 30px !default;
$m-nav-link-color: $text-strong-color !default;

.m-nav {
  &__list {
    margin: 0;
    padding: ceil($m-nav-gutter / 3) 0;
    list-style: none;
    text-align: center;
  }

  &__list-item {
    display: inline-block;
    text-transform: uppercase;
  }

  &__item {
    display: block;
    padding: ceil($m-nav-gutter / 6) ceil($m-nav-gutter / 4);
    color: $m-nav-link-color;

    &.is-active,
    &:hover,
    &:focus {
      color: $link-color;
      text-decoration: none;
    }

    &--align-element {
      display: inline-block;
      padding-top: 0;
      vertical-align: middle;
    }
  }

  &__dropdown-list {
    display: none;
    margin: 0;
    padding-left: 0;
    list-style: none;
    text-align: left;
    animation: fadeIn $base-duration;
  }

  &__dropdown-item {
    &.is-active,
    &:hover,
    &:focus {
      background-color: $dropdown-link-hover-bg;
      color: $dropdown-link-hover-color;
      text-decoration: none;
    }
  }
}
